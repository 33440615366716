<template>
    <!-- 彈窗 -->
    <Dialog :showDialog="showDialog" :customClass="'position'" @onCloseDialog="showDialog = false">
        <div class="pop-width md:p-10 px-5 py-3 bg-white rounded">
            <div class="close" @click="showDialog = false">
                <i class="fas fa-times"></i>
            </div>
            <div class="content">
                <div class="title">變更訂單狀態</div>
                <div class="status">
                    目前訂單狀態： <span class="text-orange"> {{ status | dateStatus }} </span>
                </div>
            </div>

            <div class="details">
                <div class="detail">
                    <div class="label">合計</div>
                    <div class="value">$ {{ info.totalCount | formatCurrency }}</div>
                </div>
                <div class="detail">
                    <div class="label">平台服務費</div>
                    <div class="value">$ {{ info.serviceCharge | formatCurrency }}</div>
                </div>
                <div class="detail">
                    <div class="label">訂單總額</div>
                    <div class="value">$ {{ info.totalPrice | formatCurrency }}</div>
                </div>
                <div class="detail">
                    <div class="label">會員實際已付</div>
                    <div class="value text-orange">$ {{ info.memberPaid | formatCurrency }}</div>
                </div>
            </div>

            <div class="inputs">
                <div class="input">
                    <div class="label">變更狀態</div>
                    <el-select v-model="inputs.status" size="small" class="w-full" @change="changeStatus">
                        <el-option v-for="(status, index) in payStatusOptions" :key="index" :label="status.label" :value="status.value">
                            {{ status.label }}
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="inputs">
                <div v-if="showAmount" class="input">
                    <div class="label">退還已付</div>
                    <el-input v-model="inputs.amount" size="small" type="number"> </el-input>
                </div>
            </div>
            <div class="inputs">
                <div class="input">
                    <div class="label">客訴單編號</div>
                    <el-select v-model="inputs.feedback_id" size="small" class="w-full" placeholder="請選擇客訴單編號">
                        <el-option v-for="(status, index) in userFeedbackOptions" :key="index" :label="status.label" :value="status.value">
                            {{ status.label }}
                        </el-option>
                    </el-select>
                    <!-- <el-input v-model="inputs.feedback_id" size="small"> </el-input> -->
                </div>
            </div>
            <div v-if="showNote" class="inputs">
                <div class="input">
                    <div class="label">變更原因</div>
                    <el-input v-model="inputs.note" size="small" type="textarea"> </el-input>
                </div>
            </div>
            <div class="err-msg">{{ errorMessage }}</div>
            <div class="flex justify-center mt-3 border-gray-100 pt-2">
                <el-button class="orange-btn-800 md:text-sm text-xs h-[30px]" :disabled="confirmDisabled" :loading="loading" @click="confirm">
                    確認變更
                </el-button>
            </div>
        </div>
    </Dialog>
</template>

<script>
import Dialog from "@/components/Dialog.vue";
import { handleErrorMessage } from "@/service/errorMessage";
export default {
    name: "PopUpUpdateOrder",
    components: { Dialog },
    props: {
        id: {
            type: [Number, String],
            require: true,
        },
        value: {
            type: Boolean,
            require: true,
        },
        // 訂單狀態
        status: {
            type: Number,
            require: true,
        },
        // 款項資訊
        info: {
            type: Object,
            require: true,
            default() {
                return {
                    totalCount: 0, // 訂單合計
                    serviceCharge: 0, // 服務費合計
                    totalPrice: 0, // 訂單總額
                    memberPaid: 0, // 會員實際已付
                    showPaid: 0, //畫面顯示實際已付
                    showProviderRemuneration: 0, // 服務商預期收益
                };
            },
        },
        // 客訴單選項
        userFeedbackOptions: {
            type: Array,
            require: true,
            default: () => [],
        },
    },
    computed: {
        showDialog: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            },
        },
        // 活動選項 options
        payStatusOptions() {
            return [
                {
                    label: "會員臨時取消",
                    value: -3,
                },
                {
                    label: "服務商取消",
                    value: -1,
                },
                {
                    label: "爭議處理中",
                    value: -4,
                },
                {
                    label: "爭議處理完成",
                    value: 6,
                },
            ];
        },
    },
    data() {
        return {
            // 輸入
            inputs: {
                status: null,
                amount: 0,
                feedback_id: "",
                note: "",
            },
            errorMessage: "",
            confirmDisabled: true,
            showAmount: false,
            showNote: false,
            loading: false,
        };
    },
    methods: {
        changeStatus(val) {
            if (val === 6) {
                this.inputs.amount = null;
            }
        },
        // 控制確認 Disabled
        checkDataFill(val) {
            const { status, note, feedback_id, amount } = val;
            this.confirmDisabled = true;
            if (status && feedback_id) {
                // 檢查原因欄位是否必填
                if (note) {
                    this.confirmDisabled = false;
                } else {
                    if (this.showNote === false) {
                        this.confirmDisabled = false;
                        return;
                    }
                    this.confirmDisabled = true;
                    return;
                }
                this.confirmDisabled = false;
            } else {
                this.confirmDisabled = true;
            }

            // 爭議處理完成時，金額為必填
            if (status === 6 && !amount) {
                this.confirmDisabled = true;
            }
        },
        confirm() {
            this.loading = true;
            const request = { ...this.inputs };
            if (!request.amount || request.amount === "0") {
                delete request.amount;
            }

            if (!request.feedback_id) {
                delete request.feedback_id;
            }

            if (!request.note) {
                delete request.note;
            }

            this.$api
                .UpdateDating(this.id, request)
                .then((res) => {
                    this.$emit("update", res.data.dating);
                    this.$message({
                        type: "success",
                        message: "更新成功",
                    });
                })
                .catch((err) => {
                    this.errorMessage = handleErrorMessage(err.response.data.errors);
                    this.$message({
                        type: "error",
                        message: "更新失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    watch: {
        inputs: {
            deep: true,
            handler(val) {
                const { status, note, amount } = val;
                // 必填欄位檢查
                this.confirmDisabled = status && note;
                // 畫面顯示控制
                this.showNote = this.inputs.status !== -4;
                this.showAmount = this.inputs.status === 6;
                if (!this.showNote) {
                    this.inputs.note = "";
                }
                // 服務商取消
                if (status === -1) {
                    // 訂單總額＊100%
                    this.inputs.amount = this.info.totalPrice;
                }
                // 會員臨時取消
                if (status === -3) {
                    // (合計*80%) + 平台服務費
                    this.inputs.amount = Math.ceil(this.info.price * 0.8) + this.info.serviceCharge;
                }
                // 爭議退款
                if (status === -4) {
                    this.inputs.amount = null;
                }
                // 爭議處理完成
                if (status === 6) {
                    this.errorMessage = "";
                    if (this.info.memberPaid - amount < 0) {
                        this.errorMessage = "退還已付不得大於實際已付";
                        this.confirmDisabled = true;
                        return;
                    }
                }

                // 確認資料是否完整
                this.checkDataFill(val);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.pop-width {
    width: 400px;
    margin: 0;
}
.content {
    .status {
        font-size: 14px;
        font-weight: 500;
        margin-top: 10px;
        color: #707070;
    }
}

.details {
    border-radius: 10px;
    border: 1px solid #d1d1d1;
    margin: 10px;
    padding: 5px;
    .detail {
        display: flex;
        justify-content: space-between;
        padding: 5px 15px;
        .label {
            font-weight: 500;
            font-size: 14px;
            color: #707070;
        }
        .value {
            font-weight: 500;
            font-size: 14px;
        }
    }
}

.inputs {
    .input {
        display: flex;
        align-items: center;
        padding: 10px 15px;
        .label {
            width: 150px;
            font-size: 14px;
            text-align: center;
        }
    }
}

.text-orange {
    color: #ff5733;
    font-size: 14px;
}

.err-msg {
    width: 100%;
    text-align: center;
    color: red;
    font-size: 14px;
}
</style>
